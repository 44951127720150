import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { AuthService } from "./auth.service";
import decode from "jwt-decode";
import { INavData } from "@coreui/angular";

@Injectable()
export class RoleGuardService implements CanActivate {
  tokenPayload;
  constructor(public auth: AuthService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedRoles: [any] = route.data.expectedRoles;
    const token = localStorage.getItem("token");
    // decode the token to get its payload

    if (token) this.tokenPayload = decode(token, { header: false });

    if (
      !this.tokenPayload ||
      !this.auth.isAuthenticated() ||
      this.tokenPayload.roles.filter((role: any) =>
        expectedRoles.includes(role)
      ).length < 1
    ) {
      localStorage.clear();
      this.router.navigate(["login"]);
      return false;
    }
    if (this.tokenPayload.enabled == false) {
      localStorage.clear();
      if (this.tokenPayload.roles.includes("ROLE_DISTRIBUTOR")) {
        this.router.navigate(["inactive-distributor"]);
      }
      if (this.tokenPayload.roles.includes("ROLE_BUSINESS")) {
        this.router.navigate(["inactive-business"]);
      }
      return false;
    }
    return true;
  }

  getNav(): INavData[] {
    const token = localStorage.getItem("token");
    // decode the token to get its payload

    if (token) this.tokenPayload = decode(token, { header: false });

    if (this.tokenPayload.roles.includes("ROLE_ADMIN")) {
      return [
        {
          title: true,
          name: "Panel",
        },
        {
          name: "Principal",
          url: "/admin/principal",
          icon: "fa fa-desktop",
        },
        {
          name: "Negocios",
          url: "/admin/businesses",
          icon: "fa fa-suitcase",
        },
        {
          name: "Cadetes",
          url: "/admin/distributors",
          icon: "fa fa-motorcycle",
        },
        {
          name: "Cadetes Online",
          url: "/admin/online-distributors",
          icon: "fa fa-user-check",
        },
        {
          name: "Pedidos historicos",
          url: "/admin/historic-deliveries",
          icon: "fa fa-desktop",
        },
        {
          name: "Zonas de cobertura",
          url: "/admin/zones",
          icon: "fa fa-map",
        },
      ];
    }
    if (this.tokenPayload.roles.includes("ROLE_BUSINESS")) {
      return [
        {
          title: true,
          name: "Panel",
        },
        {
          name: "Solicitar Cadete",
          url: "/dashboard",
          icon: "fa fa-motorcycle",
        },
        {
          name: "Pedidos en Curso",
          url: "/pending-deliveries",
          icon: "icon-note",
        },
        {
          name: "Historial de pedidos",
          url: "/historic-deliveries",
          icon: "fa fa-mail-reply-all",
        },
      ];
    }
    if (this.tokenPayload.roles.includes("ROLE_DISTRIBUTOR")) {
      return [
        {
          title: true,
          name: "Panel",
        },
        {
          name: "Perfil",
          url: "/profile",
          icon: "fa fa-user",
        },
      ];
    }
  }

  getHomeRoute() {
    const token = localStorage.getItem("token");
    // decode the token to get its payload

    if (token) this.tokenPayload = decode(token, { header: false });

    if (this.tokenPayload.roles.includes("ROLE_ADMIN")) {
      return "/admin/principal";
    }
    if (this.tokenPayload.roles.includes("ROLE_BUSINESS")) {
      return "/dashboard";
    }
    if (this.tokenPayload.roles.includes("ROLE_DISTRIBUTOR")) {
      console.log("distributor");
      return "/profile";
    }
  }

  isDistributor() {
    const token = localStorage.getItem("token");
    // decode the token to get its payload

    if (token) this.tokenPayload = decode(token, { header: false });

    return this.tokenPayload.roles.includes("ROLE_DISTRIBUTOR");
  }

  getProfileRoute() {
    const token = localStorage.getItem("token");
    // decode the token to get its payload

    if (token) this.tokenPayload = decode(token, { header: false });

    if (this.tokenPayload.roles.includes("ROLE_BUSINESS")) {
      return "/profile/business";
    }
    if (this.tokenPayload.roles.includes("ROLE_DISTRIBUTOR")) {
      return "/profile/distributor";
    }
  }
}
