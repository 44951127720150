import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive-business',
  templateUrl: './inactive-business.component.html',
  styles: [
  ]
})
export class InactiveBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  openWpp() {
    window.open(
      'https://api.whatsapp.com/send?phone=+5493515169146&text=Hola,%20necesito%20activar%20mi%20perfil%20como%20Negocio%20en%20la%20App%20de%20Plan%20B.',
      '_blank'
    );
  }
}
