import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {

  headers: HttpHeaders;
  constructor(private http: HttpClient, private authService: AuthService) {}

  getZones(page: number): Observable<any>{
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.get(`${environment.BACKEND}/zone?page=${page}`, {headers: this.headers});
  }

  getAllZones(): Observable<any>{
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.get(`${environment.BACKEND}/zone/all`, {headers: this.headers});
  }

  saveNewZone(zone: any): Observable<any>{
    this.headers = new HttpHeaders({
      'Content-Type':  'application/json',
      'Authorization': `Bearer ${this.authService.getToken()}`
    })
    return this.http.post(`${environment.BACKEND}/zone`, zone, {headers: this.headers});
  }
}
