import { Component } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { RoleGuardService } from '../../services/role-guard.service';
import { UsersService } from '../../services/user.service';

@Component({
  selector: "app-dashboard",
  templateUrl: "login.component.html",
})
export class LoginComponent {
  email: string;
  password: string;
  loading: boolean;

  ngOnInit(): void {
      this.route.fragment.subscribe(fragment => {
        console.log(fragment);        
        if (fragment == 'registered'){
          console.log("notif");
          
          this.notification.successMessage(
            'Registro completo!'
          );
        }
      });
  }

  constructor(
    public userService: UsersService,
    public router: Router,
    public roleGuardService: RoleGuardService,
    public notification: NotificationService,
    public route: ActivatedRoute
  ) {
    this.loading = false;
  }

  

  login() {
    const user = {
      email: this.email,
      password: this.password,
      deviceToken: "web",
    };
    this.loading = true;
    this.userService.login(user).subscribe(
      (data) => {
        if (data.status == 200) {
          this.loading = false;
          localStorage.setItem("token", data.body.accessToken);
          this.router.navigate(['/']);
        }
      },
      (error) => {
        this.notification.errorMessage("Usuario invalido");
        this.loading = false;
      }
    );
  }
}
