import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  headers: HttpHeaders;
  constructor(private http: HttpClient, private authService: AuthService) {}

  login(user: any): Observable<any> {
    return this.http.post(`${environment.BACKEND}/auth/signin`, user, { observe: 'response' });
  }
  getUser(): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.get(`${environment.BACKEND}/user`, { headers: this.headers });
  }

  businessRegister(registerRequest: any): Observable<any> {
    return this.http.post(`${environment.BACKEND}/auth/business/signup`, registerRequest);
  }

  distributorRegister(registerRequest: any): Observable<any> {
    return this.http.post(`${environment.BACKEND}/auth/distributor/signup`, registerRequest);
  }

  resetPassword(user: any) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.put(
      `${environment.BACKEND}/auth/password-reset/${user}`,
      null,
      {
        headers: this.headers,
      }
    );
  }

  toggleUserEnabled(user: number): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.put(`${environment.BACKEND}/user/enabled/${user}`, null, {
      headers: this.headers,
    });
  }

  updateBusinessUser(updateBusiness: any): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.put(`${environment.BACKEND}/business`, updateBusiness, {
      headers: this.headers,
    });
  }

  updateUser(id: number, updateUser: any): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.put(`${environment.BACKEND}/user/${id}`, updateUser, {
      headers: this.headers,
    });
  }

  deleteUser(id: number): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.delete(`${environment.BACKEND}/user/${id}`, {
      headers: this.headers,
    });
  }

  updateDistributorUser(updateDistributor: any): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.put(`${environment.BACKEND}/distributor`, updateDistributor, {
      headers: this.headers,
    });
  }

  getAllDistributors(page: number): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.get(`${environment.BACKEND}/distributor?page=${page}`, {
      headers: this.headers,
    });
  }

  getOnlineDistributors(page: number, searchString: any): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return searchString ? this.http.get(`${environment.BACKEND}/distributor/online?page=${page}&searchString=${searchString}`, {
      headers: this.headers,
    }) : this.http.get(`${environment.BACKEND}/distributor/online?page=${page}`, {
      headers: this.headers,
    });
  }

  getAllDistributorsBySearch(page: number, searchString): Observable<any> {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authService.getToken()}`,
    });
    return this.http.get(
      `${environment.BACKEND}/distributor/search?page=${page}&searchString=${searchString}`,
      {
        headers: this.headers,
      }
    );
  }
}
