import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../../services/notification.service';
import { UsersService } from '../../../services/user.service';
import { ZoneService } from '../../../services/zone.service';

@Component({
  selector: 'app-distributor-register',
  templateUrl: './distributor-register.component.html',
  styles: [
  ]
})
export class DistributorRegisterComponent implements OnInit {
  name = { error: false, value: "" };

  email = { error: false, value: "" };
  password = { error: false, value: "" };
  phoneNumber = { error: false, value: "" };
  surname = { error: false, value: "" };
  passwordCheck = { error: false, value: "" };

  distributorZones = [];
  zones = [];
  selectedZone = 0;

  loading = false;

  constructor(
    private userService: UsersService,
    private router: Router,
    private notification: NotificationService,
    private zoneService: ZoneService
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.zoneService.getAllZones().subscribe(
      (value) => {
        this.zones = value;
        console.log(value);
        this.loading = false;
        this.selectedZone = undefined;
      },
      (errorResponse: HttpErrorResponse) => {
        this.loading = false;
        this.notification.errorMessage(
          "Ocurrio un error: " + errorResponse.error.message
        );
      }
    );
  }

  deleteZone(zone: any) {
    this.zones.push(zone);
    this.distributorZones = this.distributorZones.filter(
      (item: any) => item !== zone
    );
    this.selectedZone = undefined;
  }

  addZone() {
    if (this.zones.length > 0 || this.selectedZone !== undefined) {
      this.distributorZones.push(this.selectedZone);
      this.zones = this.zones.filter((zone: any) => zone !== this.selectedZone);
    }
  }

  removeInvalidPhoneChars() {
    this.phoneNumber.value = this.phoneNumber.value.replace(/\D/g, "");
  }

  register() {
    if (this.validateForm()) {
      this.loading = true;
      const registerRequest = {
        name: this.name.value.trim(),
        email: this.email.value.trim(),
        password: this.password.value,
        phoneNumber: this.phoneNumber.value,
        surname: this.surname.value.trim(),
        zonesIds: this.distributorZones.map((item: any) => item.id)
      };
      this.userService.distributorRegister(registerRequest).subscribe(
        (resp) => {
          this.loading = false;
          this.router.navigate(["/login"], { fragment: "registered" });
        },
        (errorResponse: HttpErrorResponse) => {
          this.loading = false;
          this.notification.errorMessage(
            "Ocurrio un error: " + errorResponse.error.message
          );
        }
      );
    }
  }

  validateForm(): boolean {
    this.removeInvalidPhoneChars();
    var isValid = true;

    this.email.value = this.email.value.trim();

    console.log(this.email);
    console.log(this.email.value.length);

    if (this.name.value.length < 2 || this.name.value.length > 40) {
      this.name.error = true;
      isValid = false;
    } else this.name.error = false;

    if (this.password.value !== this.passwordCheck.value) {
      this.passwordCheck.error = true;
      isValid = false;
    } else this.passwordCheck.error = false;

    if (this.surname.value.length < 2 || this.surname.value.length > 40) {
      this.surname.error = true;
      isValid = false;
    } else this.surname.error = false;

    if (
      this.email.value.length < 2 ||
      this.email.value.length > 40 ||
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        this.email.value
      )
    ) {
      this.email.error = true;
      isValid = false;
    } else this.email.error = false;

    if (this.distributorZones.length < 1) isValid = false;

    if (this.phoneNumber.value.length !== 10) {
      this.phoneNumber.error = true;
      isValid = false;
    } else this.phoneNumber.error = false;


    if (this.password.value.length < 2 || this.password.value.length > 40) {
      this.password.error = true;
      isValid = false;
    } else this.password.error = false;

    return isValid;
  }

}
