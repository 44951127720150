import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
import { RoleGuardService } from './services/role-guard.service';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { InactiveBusinessComponent } from './views/inactive-business/inactive-business.component';
import { InactiveDistributorComponent } from './views/inactive-distributor/inactive-distributor.component';
import { LoginComponent } from './views/login/login.component';
import { CostsComponent } from './views/public/costs/costs.component';
import { BusinessRegisterComponent } from './views/register/business-register/business-register.component';
import { DistributorRegisterComponent } from './views/register/distributor-register/distributor-register.component';
import { RegisterComponent } from './views/register/register.component';
import { RoleSelectionComponent } from './views/register/role-selection/role-selection.component';

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: 'costs',
    component: CostsComponent,
    data: {
      title: 'Costs'
    }
  },
  {
    path: 'inactive-business',
    component: InactiveBusinessComponent,
    data: {
      title: 'Inactive Business'
    }
  },
  {
    path: 'inactive-distributor',
    component: InactiveDistributorComponent,
    data: {
      title: 'Inactive distributor'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    children: [
      {
        path: 'business',
        component: BusinessRegisterComponent,
        data: {
          title: 'Business register Page'
        }
      },
      {
        path: 'distributor',
        component: DistributorRegisterComponent,
        data: {
          title: 'Distributor register Page'
        }
      },
      {
        path: '',
        component: RoleSelectionComponent,
        data: {
          title: 'Login Page'
        }
      },
    ],
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate : [RoleGuardService],
    data: {
      expectedRoles: ['ROLE_BUSINESS', 'ROLE_ADMIN', 'ROLE_DISTRIBUTOR'],
    },
    children: [
      {
        path: 'base',
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'buttons',
        loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule)
      },
      {
        path: 'charts',
        loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule)
      },
      {
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['ROLE_BUSINESS']
        },
        path: 'dashboard',
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['ROLE_BUSINESS']
        },
        path: 'pending-deliveries',
        loadChildren: () => import('./views/pedidos-en-curso/pedidos-en-curso.module').then(m => m.PedidosEnCursoModule)
      },
      {
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['ROLE_BUSINESS']
        },
        path: 'historic-deliveries',
        loadChildren: () => import('./views/historic-deliveries/historic-deliveries.module').then(m => m.HistoricDeliveriesModule)
      },
      {
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['ROLE_BUSINESS','ROLE_DISTRIBUTOR']
        },
        path: 'profile',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        canActivate: [RoleGuardService],
        data: {
          expectedRoles: ['ROLE_ADMIN']
        },
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then(m => m.Admin)
      },
      {
        path: 'icons',
        loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule)
      },
      {
        path: 'theme',
        loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule)
      },
      {
        path: 'widgets',
        loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule)
      }
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
