import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { INavData } from '@coreui/angular';
import { RoleGuardService } from '../../services/role-guard.service';
import { navItems } from '../../_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit{
  public sidebarMinimized = false;
  public navItems;

  ngOnInit(){
      this.navItems = this.roleGuardService.getNav();
      this.router.navigate([this.roleGuardService.getHomeRoute()])
  }

  constructor(private router: Router, private roleGuardService: RoleGuardService){}

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
  logout(){
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
