import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { RoleGuardService } from './services/role-guard.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { JwtHelperService, JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { BusinessRegisterComponent } from './views/register/business-register/business-register.component';
import { InactiveBusinessComponent } from './views/inactive-business/inactive-business.component';
import { FooterComponent } from './shared/footer/footer.component';
import { InactiveDistributorComponent } from './views/inactive-distributor/inactive-distributor.component';
import { RoleSelectionComponent } from './views/register/role-selection/role-selection.component';
import { DistributorRegisterComponent } from './views/register/distributor-register/distributor-register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CostsComponent } from './views/public/costs/costs.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    HttpClientModule,
    ChartsModule,
    CommonModule,
    FormsModule,
    ToastrModule.forRoot(),
    NgbModule,
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    BusinessRegisterComponent,
    InactiveBusinessComponent,
    FooterComponent,
    InactiveDistributorComponent,
    RoleSelectionComponent,
    DistributorRegisterComponent,
    CostsComponent,
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy
  },
  RoleGuardService,
  AuthGuardService,
  AuthService,
  JwtHelperService,
  { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
