import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private toastr: ToastrService) {}

  successMessage(message: any){
    this.toastr.success(message, "Exito",{
      positionClass: 'toast-top-right'
    })
  }

  errorMessage(message: any){
    this.toastr.error(message, "Error",{
      positionClass: 'toast-top-right'
    })
  }
}
